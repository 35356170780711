import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/index.css'
// import 'feather-icons'
// import featherIcon from 'feather-icons-vue'
import '@/assets/css/public.scss'

// import VueClipboard from 'vue-clipboard2';
import globalJson from './globalJson.json';
import { Message } from 'element-ui';

// 文本溢出隐藏
import EllipsisText from '@/components/EllipsisText.vue';
// 浮动操作按钮
import floatActionBtn from '@/components/floatActBtn.vue';

Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(VueClipboard)

// Vue.component('featherIcon', featherIcon)
Vue.component('EllipsisText', EllipsisText)
Vue.component('floatActionBtn', floatActionBtn)
//全局测试数据
Vue.prototype.$globalJson = globalJson;
//裁剪字符串
Vue.prototype.$truncateText = function (text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
};
/*复制输入框内容到剪贴板*/
Vue.prototype.$copyValue = function (value) {
  if (!value) {
    Message.error('值为空，无法复制')
  } else {
    navigator.clipboard.writeText(value)
      .then(() => {
        Message.success('已复制到剪贴板')
      })
      .catch(() => {
        Message.error('复制失败')
      });
  }
},

  // 限制并发 requestList:请求队列 | limits:并发数限制 | callback:回调函数
  Vue.prototype.$sendRequest = async function (requestList, limits, callback) {
    // 维护一个promise队列
    const promises = [];
    // 并发池，用Set结构方便删除
    const pool = new Set();

    // 开始并发执行所有任务
    for (let request of requestList) {
      // 开始执行前，先await判断当前的并发任务是否超过限制
      if (pool.size >= limits) {
        await Promise.race(pool).catch((err) => err);
      }
      // 拿到promise
      const promise = request();
      const cb = () => {
        // 移出并发池
        pool.delete(promise);
      };

      // 注册then的任务
      promise.then(cb, cb);
      // 添加到并发池
      pool.add(promise);

      promises.push(promise);
    }

    Promise.allSettled(promises).then(callback, callback)
  }

/*转换时间戳*/
Vue.prototype.$formatteTime = function (timestamp) {


  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = date.getSeconds();

  const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDateTime
},

  // 修改Message显示时间
  ['success', 'warning', 'info', 'error'].forEach(type => {
    // console.log(ElementUI.Message);
    if (ElementUI.Message[type]) {
    ElementUI.Message[type] = options => {
      if (typeof options === 'string') {
        options = {
          message: options
        };
        options.duration = 800
      }
      options.type = type;
      return ElementUI.Message(options);
    };
    }
  });


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

